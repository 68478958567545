import { isInstantQuoteOccupancy } from "../api/staticDataAPIs"

const checkIfInstantQuoteOccupancy = async (typeOfFacility, natureOfBusiness) => {
  let occupancy = `${typeOfFacility}:-${natureOfBusiness}`
  if (!natureOfBusiness) {
    occupancy = typeOfFacility
  }
  return await isInstantQuoteOccupancy(occupancy)
}

export default checkIfInstantQuoteOccupancy