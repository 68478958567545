import { sendApiRequest } from "./apiUtils"

// for creating new contact before requesting OTP
export const createContact = async (ProposerName, Phone) => {
  if (!Phone || !ProposerName) {
    return null
  }
  return await sendApiRequest('/public/contacts', { ProposerName, Phone }, 'POST')
}

// OTP Login
export const getLoginOTP = async phone => {
  if (!phone) {
    return null
  }
  return await sendApiRequest('/auth/contact/otp', { phone }, 'POST')
}

// auto rfq gen
export const startRfqGenTask = async (data) => {
  if (!data) return
  return sendApiRequest('/public/tasks', data)
}

export const stopTask = async (taskId) => {
  if (!taskId) return 
  return sendApiRequest(`/public/tasks/${taskId}`, null, 'DELETE')
}

export const getAuthToken = async (phone, otp) => {
  if (!phone || !otp) {
    return null
  }
  return await sendApiRequest('/auth/contact/token', { phone, otp }, 'POST')
}

export const getStoredAuthToken = () => {
  return sessionStorage.getItem('token')
}

export const updateClientDetails = async (data, rfqId) => {
  if (!data && !rfqId) return
  return await sendApiRequest(`/client/rfqs/${rfqId}/customer-details`, data, 'PUT', true)
}

export const updateRfq = async (data, rfqId) => {
  if (!data && !rfqId) return
  return await sendApiRequest(`/client/rfqs/${rfqId}`, data, 'PUT', true)
}

export const resubmitRfq = async (data, rfqId) => {
  if (!data && !rfqId) return
  return await sendApiRequest(`/client/rfqs/${rfqId}/resubmit`, data, 'POST', true)
}

// create RFQ, get and select quotes
export const generateRfq = async () => {
  const reqBody = { Source: "WEB" }
  return await sendApiRequest(`/client/rfqs`, reqBody, 'POST', true)
}

export const getRfqData = async (rfqId) => {
  return await sendApiRequest(`/client/rfqs/${rfqId}`, null, 'GET', true)
}

export const getRfqList = async () => {
  return await sendApiRequest('/client/rfqs', null, 'GET', true)
}

export const getQuotes = async (rfqId) => {
  if (!rfqId) {
    return null
  }
  return await sendApiRequest(`/client/rfqs/${rfqId}/quotes`, null, 'GET', true)
}

export const selectQuote = async (rfqId, quoteId) => {
  if (!rfqId || !quoteId) return
  return await sendApiRequest(`/client/rfqs/${rfqId}/quotes/${quoteId}`, {}, 'PUT', true)
}

// payment apis
export const generatePaymentLink = async rfqId => {
  if (!rfqId) return null
  return await sendApiRequest(`/client/rfqs/${rfqId}/paymentLink`, { Source: 'WEBSITE' }, 'POST', true)
}

export const getPaymentStatus = async (rfqId) => {
  if (!rfqId) return null
  const result = await sendApiRequest(
    `/client/rfqs/${rfqId}/payment-details`,
    null,
    'GET',
    true
  )
  return result
}

export const getPaymentMandate = async (rfqId, Insurer) => {
  if (!rfqId) return null
  const result = await sendApiRequest(
    `/client/static/PAYMENT_MANDATE?insurer=${Insurer}`,
    null,
    'GET',
    true
  )
  return result
}