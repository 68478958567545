import React, { useImperativeHandle } from "react"
import loadable from "@loadable/component"
import styled from "styled-components"
import { navigate } from "gatsby";

import { Loader } from './core/Loader';
import { useMixpanel } from "../context/MixpanelContext";
import { isBrowser } from "../utils/browserUtils";
import { getPageName } from "../utils/getCurrentPageName";
import { getQueryParams } from "../utils/queryParamUtils";
import { Text } from "./core/commonExports";
import { allNOBs, fieldWiseProspectingStates, collectGoodsDataForToFs, hideNOBforBusinessTypes, roofMaterialsList, typesOfFacilities, wallMaterialsList } from "./LeadFormSteps/sharedData";

import CloseIcon from '../assets/images/close_icon.svg';
import trackGoogleAdConversion from "../utils/trackGoogleAdConversion";
import { getProvisionalQuote, sendLead, sendProspectingData, sendOtherValuePing } from "../api/prospectingAPIs";
import { mobileNumWithCountryCode, trackMixpanelEvent } from "./LeadFormSteps/sharedUtils";
import { useLeadFormData } from "../context/LeadFormContext";
import { actions } from "../context/LeadFormReducer";
import { screens } from "../hooks/useCampaignLPStates";
import { createContact } from "../api/selfServeAPIs";
import checkIfInstantQuoteOccupancy from "../utils/checkIfInstantOccupancy";

const PolicyTypeSelection = loadable(() => import("./LeadFormSteps/PolicyTypeSelection"))
const LeadDataCollection = loadable(/* webpackPrefetch: true */ () => import("./LeadFormSteps/LeadDataCollection"))
const EstimatedQuote = loadable(() => import("./LeadFormSteps/EstimatedQuote"))
const ThankYouScreen = loadable(() => import("./LeadFormSteps/ThankYouScreen"))

// new QnA journey screens
const BusinessTypeSelection = loadable(() => import("./LeadFormSteps/BusinessTypeSelection"))
const RoofWallMaterialsCollection = loadable(() => import("./LeadFormSteps/RoofWallMaterialsCollection"))
const GoodsDataCollection = loadable(() => import("./LeadFormSteps/GoodsDataCollection"))
const MaterialsDataCollection = loadable(() => import("./LeadFormSteps/MaterialsDataCollection"))
const SumInsuredCollection = loadable(() => import("./LeadFormSteps/SumInsuredCollection"))
const AddressCollection = loadable(() => import("./LeadFormSteps/AddressCollection"))

// list of screens to show for each form
const commonFormScreens = {
  leadDataCollection: "lead-data",
  provisionalQuote: "provisional-quote",
  businessTypeSelection: "business-type-data",
  roofWallMaterialsCollection: "roof-wall-materials-data",
  goodsDataCollection: "goods-data",
  materialDataCollection: "material-data",
  sumInsuredCollection: "sum-insured-data",
  addressCollection: "address-data",
}

const googleForm = { 
  ...commonFormScreens,
}

const websiteForm = {
  ...commonFormScreens,
  policyTypeChoice: "select-policy-type",
  leadDataCollectionSuccess: "lead-data-success"
}

const safetyPlanForm = {
  leadDataCollection: "lead-data",
  leadDataCollectionSuccess: "lead-data-success"
}

const LoadingComp = () => {
  return (
    <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "405px"}}>
      <Loader />
    </div>
  )
}

const scrollToTop = () => {
  if (isBrowser) {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}

const LeadForm = ({ 
  quoteCtaProps={},
  newTheme=false, 
  formType="website", 
  onModalClose, 
  renderAsPopup = true, 
  style={}, 
  setHideQuoteCTAs, 
  hideMinPremium = () => {}, 
  setHeroSectionScreen = () => {}, 
}, ref) => {
  const mixpanel = useMixpanel()
  const [leadFormData, dispatch] = useLeadFormData()

  const [screen, setScreen] = React.useState(() => {
    if (formType === "google")
      return googleForm.leadDataCollection
    else if (formType === "safety-plan")
      return safetyPlanForm.leadDataCollection
    else 
      return websiteForm.policyTypeChoice
  })

  // lead form data
  const [companyName, setCompanyName] = React.useState(leadFormData?.companyName || '');
  const [customerName, setCustomerName] = React.useState(leadFormData?.customerName || '')
  const [mobile, setMobile] = React.useState(leadFormData?.mobile?.replace('+91', '') || '')
  const [waUpdates, setWaUpdates] = React.useState(leadFormData?.waUpdates || true)
  const [pincode, setPincode] = React.useState(leadFormData?.pincode || '')
  const [policyType, setPolicyType] = React.useState('')
  const [products, setProducts] = React.useState([])

  // prospecting form data
  const [typeOfFacility, setTypeOfFacility] = React.useState(leadFormData?.typeOfFacility || '')
  const [natureOfBusiness, setNatureOfBusiness] = React.useState(leadFormData?.natureOfBusiness || '')
  const [roofMaterial, setRoofMaterial] = React.useState(leadFormData?.roofMaterial || '')
  const [wallMaterial, setWallMaterial] = React.useState(leadFormData?.wallMaterial || '')
  const [sumInsured, setSumInsured] = React.useState(leadFormData?.sumInsured || '')
  const [goods, setGoods] = React.useState(leadFormData?.goods || '')
  const [rawMaterials, setRawMaterials] = React.useState(leadFormData?.rawMaterials || '')
  const [finishedGoods, setFinishedGoods] = React.useState(leadFormData?.finishedGoods || '')
  const [address, setAddress] = React.useState(leadFormData?.address || '')
  const [floor, setFloor] = React.useState(leadFormData?.floor || '')
  const [ownership, setOwnership] = React.useState(leadFormData?.ownership || '')
  const [sumInsuredValues, setSumInsuredValues] = React.useState(leadFormData?.sumInsuredValues || {
    Stock: "",
    Structure: "",
    Machines: "",
    "IT Equipment": "",
    Furniture: "",
    "Electrical Equipments": "",
    "Other": ""
  })
  const [otherItemsDesc, setOtherItemsDesc] = React.useState(leadFormData?.otherItemsDesc || '')
  const [stockData, setStockData] = React.useState({
    rawMaterials: "",
    finishedGoods: "",
    goods: "" // only for shop
  })
  const [city, setCity] = React.useState(leadFormData?.city || '')
  const [stateVal, setStateVal] = React.useState(leadFormData?.stateVal || '')
  
  // estimated quote data
  const [estimatedQuote, setEstimatedQuote] = React.useState({
    minValue: 0,
    maxValue: 0
  })

  // api call states
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [error, setError] = React.useState('')
  const [isAutoSaving, setIsAutoSaving] = React.useState(false)
  const [isAutoSaveError, setIsAutoSaveError] = React.useState(false)

  const getEstimatedPremium = async () => {
    setIsSubmitting(true)
    const prospectingData = {
      Phone: mobileNumWithCountryCode(mobile),
      Data: {},
      Status: fieldWiseProspectingStates.prospectDataCollected
    }
    await sendProspectingData(prospectingData)
    const provisionalQuoteData = {
      Source: 'WEBSITE',
      Phone: mobileNumWithCountryCode(mobile),
      SumInsured: Number(sumInsured),
      PIN: pincode,
      Facility: typeOfFacility,
      NatureOfBusiness: natureOfBusiness
    }
    const data = await getProvisionalQuote(provisionalQuoteData)
    if (data.minValue && data.maxValue) {
      setEstimatedQuote({
        minValue: data.minValue,
        maxValue: data.maxValue
      })
    }
    setIsSubmitting(false)
    hideMinPremium()
    setHeroSectionScreen("regional-stats")
    setScreen(googleForm.provisionalQuote)
    !renderAsPopup && scrollToTop()
  }

  useImperativeHandle(ref, () => ({
    fetchEstimatedPremium: () => {
      setHeroSectionScreen('total-stats')
      return getEstimatedPremium()
    }
  })
  )

  // to allow scrolling of the popup even if the content is lengthy
  React.useEffect(() => {
    if (renderAsPopup) {
      document.querySelector('html').style.overflow = "hidden"
    }
    
    return () => document.querySelector('html').style.overflow = "auto"
  }, [])

  const handleMobileNumber = (e) => {
    const val = e.target.value;
    if (val.length <= 10) {
      setMobile(e.target.value);
    }
    // NOTE: This is a temporary hack for the mobile number validation due to preact tel input not working properly
    e.target.value = val.slice(0, 10);
    e.target.value.replace(/[^0-9]g/, '')
  }
  
  const handlePincode = (e) => {
    const val = e.target.value;
    if (val.length <= 6) {
      setPincode(e.target.value);
    }
    e.target.value = val.slice(0, 6);
    e.target.value.replace(/[^0-9]g/, '')
  }

  const setTypeOfFactlityValue = (val) => {
    setTypeOfFacility(val)
    setNatureOfBusiness('')
  }

  const pageName = getPageName()
  const isSafetyPlan = formType === "safety-plan"

  const handleClose = () => {
    if (screen === commonFormScreens.provisionalQuote && formType !== "website") {
      trackMixpanelEvent(mixpanel, 'Viewed Testimonials page', pageName)
      navigate("/our-customers")
    }
    onModalClose();
  };

  const openForm = !renderAsPopup ? " (Open Form)" : " (Popup)"

  // save lead data to the context everytime proceed button is clicked
  const saveLeadDataToContext = async (action, data=null) => {
    if (!action) return 

    switch(action) {
      case actions.setLeadData:
        data = { companyName, customerName, mobile, waUpdates }
        break;
      case actions.setBusinessTypeData:
        data = { typeOfFacility, natureOfBusiness }
        break;
      case actions.setRoofWallsData:
        data = { roofMaterial, wallMaterial }
        break;
      case actions.setStockData:
        if (!data) {
          data = { goods, rawMaterials, finishedGoods, sumInsured, sumInsuredValues }
        }
        break;
      case actions.setSumInsuredData:
        data = { ownership, sumInsuredValues, otherItemsDesc, sumInsured }
        break;
      case actions.setAddressData:
        data = { pincode, address, city, stateVal, floor }
        break;
      default: 
    }

    if (!data) return
    
    return await new Promise(resolve => {
      dispatch({ type: action, data })
      resolve()
    })
  }

  const handleApiError = (e) => {
    console.log(e)
    setIsSubmitting(false)
    setError("Some error occured")
    setTimeout(() => setError(""), 5000)
  }

  const handlePolicyTypeSelection = (option) => {
    setPolicyType(option)
    if (option === "business-property") {
      setProducts(["Fire", "Burglary"])
    } else if (option === "business-liability") {
      setProducts(["Business Liability"])
    }
    setTimeout(() => setScreen(websiteForm.leadDataCollection), 800)
  }

  const handleLeadDataSubmit = async (e) => {
    e.preventDefault()
    let eventName = `Clicked 'Get a Quote'${openForm}`
    if (isSafetyPlan) {
      eventName = `Clicked '${quoteCtaProps?.label || "GET PLANS"}'${openForm}`
    }
    trackMixpanelEvent(mixpanel, eventName, pageName)

    const queryParams = getQueryParams()
    const campaign = queryParams?.get('utm_campaign') || ''

    const lead = {
      Source: 'COMMERCIAL_LP',
      Products: products,
      CompanyName: companyName.toUpperCase().trim(),
      GSTIN: 'NA',
      Turnover: '',
      CustomerName: customerName,
      Phone: mobileNumWithCountryCode(mobile),
      WaConsent: waUpdates
    };

    if (campaign) {
      lead.LeadSource = campaign
    }

    if (!pageName && isSafetyPlan) {
      lead.Source = "SFT_LP"
    }
    if (pageName.includes("GLP") || pageName.includes("Google")) {
      lead.Source = "GOOGLE_LP"
    }
    // for now, send GOOGLE_LP as Source for Facebook LPs
    if (pageName.includes("FB_LG")) { 
      lead.Source = "GOOGLE_LP"
    }
    if (pageName.includes("FBLP") || pageName.includes("FBSFT")) {
      lead.Source = "FB_LP"
    }
    if (pageName.includes("YTLP")) {
      lead.Source = "YT_LP"
    }

    const isBusinessLiabilityFlow = formType === "website" && policyType === "business-liability"

    // preload the next page
    if (isBusinessLiabilityFlow || isSafetyPlan) {
      ThankYouScreen.preload()
    } else {
      BusinessTypeSelection.preload()
    }

    if (!renderAsPopup) {
      setHideQuoteCTAs(true)
    }

    try {
      setIsSubmitting(true)
      await saveLeadDataToContext(actions.setLeadData)
      await sendLead(lead)
      if (pageName.includes("GLP") || pageName.includes("Google") || pageName.includes("YTLP")) {
        trackGoogleAdConversion()
      }
      setIsSubmitting(false)
      if (isBusinessLiabilityFlow || isSafetyPlan) {
        setScreen(websiteForm.leadDataCollectionSuccess)
      } else {
        setScreen(commonFormScreens.businessTypeSelection)
      }
      !isSafetyPlan && setHeroSectionScreen('claim-testimonial')
      !renderAsPopup && scrollToTop()
    } catch (e) {
      handleApiError(e)
    }
  };

  const getProspectingData = (data, status) => {
    if (!status) return 
    return ({
      Phone: mobileNumWithCountryCode(mobile),
      Data: data,
      Status: status
    })
  }

  const autoSaveData = async (fieldName, data) => {
    if (!fieldName || !data) return 
    
    const status = fieldWiseProspectingStates?.[fieldName]
    if (!status) return

    const reqBody = {
      Phone: mobileNumWithCountryCode(mobile),
      Data: data,
      Status: status
    }
    setIsAutoSaving(true)
    setIsAutoSaveError(false)
    await sendProspectingData(reqBody).catch(e => {
      setIsAutoSaving(false)
      setIsAutoSaveError(true)
      handleApiError(e)
    })
    setIsAutoSaving(false)
  }

  const otherValuePing = async (data={}) => {
    if (!data.Question || !data.Value) return

    const otheValueData = {
      Phone: mobileNumWithCountryCode(mobile),
      Source: 'Web',
      ...data
    }
    return await sendOtherValuePing(otheValueData)
  }

  const handleBusinessTypeDataSubmit = async (e) => {
    e.preventDefault()
    trackMixpanelEvent(mixpanel, `Clicked 'Proceed - Business Type Selection'`, pageName)
    // preload next screen
    RoofWallMaterialsCollection.preload()

    try {
      setIsSubmitting(true)
      await saveLeadDataToContext(actions.setBusinessTypeData)
      // API call made only if autosave has failed
      if (isAutoSaveError) {
        const data = {
          FacilityType: typeOfFacility,
          BusinessType: natureOfBusiness,
        }
        const prospectingData = getProspectingData(data, fieldWiseProspectingStates.natureOfBusiness)
        await sendProspectingData(prospectingData)
        setIsAutoSaveError(false)
      }
      if (!typesOfFacilities.includes(typeOfFacility)) {
        await otherValuePing({
          Question: 'TOF',
          Value: typeOfFacility,
        })
      }
      if (!allNOBs.includes(natureOfBusiness)) {
        await otherValuePing({
          Question: 'NOB',
          Value: natureOfBusiness,
        })
      }
      setIsSubmitting(false)
      // show total stats on the hero section
      setHeroSectionScreen(screens.totalStats)
      setScreen(googleForm.roofWallMaterialsCollection)
      !renderAsPopup && scrollToTop()
    } catch (err) {
      handleApiError(error)
    }
  }

  const handleRoofWallMaterialsDataSubmit = async (e) => {
    e.preventDefault()
    trackMixpanelEvent(mixpanel, `Clicked 'Proceed - Roof Wall Materials Selection'`, pageName)

    // preload next screen
    if (collectGoodsDataForToFs.includes(typeOfFacility) || hideNOBforBusinessTypes.includes(typeOfFacility)) {
      GoodsDataCollection.preload()
    } else if (typeOfFacility === "Godown/Warehouse" || typeOfFacility === "Factory/Manufacturer") {
      MaterialsDataCollection.preload()
    } else {
      SumInsuredCollection.preload()
    }

    // API call
    try {
      setIsSubmitting(true)
      await saveLeadDataToContext(actions.setRoofWallsData)
      // make this API call only if autosave failed before
      if (isAutoSaveError) {
        const data = {
          WallMaterial: wallMaterial,
          RoofMaterial: roofMaterial
        }
        const prospectingData = getProspectingData(data, fieldWiseProspectingStates.wallMaterial)
        await sendProspectingData(prospectingData)
        isAutoSaveError(false)
      }
      // call this api if some custom material type is entered
      if (!roofMaterialsList.includes(roofMaterial)) {
        await otherValuePing({
          Question: 'Roof',
          Value: roofMaterial,
        })
      }
      if (!wallMaterialsList.includes(wallMaterial)) {
        await otherValuePing({
          Question: 'Wall',
          Value: wallMaterial,
        })
      }

      // show total stats on the hero section
      setHeroSectionScreen(screens.totalStats)

      if (collectGoodsDataForToFs.includes(typeOfFacility) || hideNOBforBusinessTypes.includes(typeOfFacility) || !typesOfFacilities.includes(typeOfFacility)) {
        setIsSubmitting(false)
        setScreen(googleForm.goodsDataCollection)
      } else if (typeOfFacility === "Godown/Warehouse" || typeOfFacility === "Factory/Manufacturer") {
        setIsSubmitting(false)
        setScreen(googleForm.materialDataCollection)
      } else {
        // show POA directly on the hero section
        setHeroSectionScreen(screens.factsAboutUs)
        // send a Partial prospect 3 ping here w/ no data
        const partialProspect3Data = getProspectingData({}, fieldWiseProspectingStates.goodsData)
        // make 2nd API call here (w/ empty data)
        await saveLeadDataToContext(actions.setStockData)
        await sendProspectingData(partialProspect3Data)
        setIsSubmitting(false)
        setScreen(googleForm.sumInsuredCollection)
      }
      !renderAsPopup && scrollToTop()
    } catch (err) {
      handleApiError(error)
    }
  }

  const handleGoodsMaterialsDataSubmit = async (e) => {
    e.preventDefault()
    // send updated totalSI and stock value
    let data = {
      AssetValue: Number(sumInsured),
      SumInsuredBreakdown: getSiBreakdown(sumInsuredValues),
    }
    let eventName = ''
    const isGoodsCollectionScreen = screen === googleForm.goodsDataCollection
    if (isGoodsCollectionScreen) {
      data.NatureOfStock = goods
      data.RawMaterial = ""
      data.FinishedGoods = ""
      eventName = `Clicked 'Proceed - Goods Data collection'`
    } else if (screen === googleForm.materialDataCollection) {
      data.NatureOfStock = ""
      data.RawMaterial = rawMaterials
      data.FinishedGoods = finishedGoods
      eventName = `Clicked 'Proceed - Materials Data collection'`
    }
    trackMixpanelEvent(mixpanel, eventName, pageName)
    
    // preload next screen
    SumInsuredCollection.preload()

    try {
      // API call here
      setIsSubmitting(true)
      const contextData = {
        goods: data.NatureOfStock,
        rawMaterials: data.RawMaterial,
        finishedGoods: data.FinishedGoods,
        sumInsured, 
        sumInsuredValues
      }
      await saveLeadDataToContext(actions.setStockData, contextData)
      if (isGoodsCollectionScreen) {
        await sendProspectingData(getProspectingData(data, fieldWiseProspectingStates.goodsData))
      } else {
        await sendProspectingData(getProspectingData(data, fieldWiseProspectingStates.rawMaterials))
        await sendProspectingData(getProspectingData(data, fieldWiseProspectingStates.finishedGoods))
      }
      setIsSubmitting(false)
      setIsAutoSaveError(false)
      setHeroSectionScreen(screens.factsAboutUs)
      setScreen(googleForm.sumInsuredCollection)
      !renderAsPopup && scrollToTop()
    } catch (err) {
      handleApiError(err)
    }
  }

  const getSiBreakdown = (filteredSiValues={}) => {
    return ({
      Stock: filteredSiValues?.Stock || '',
      PlantMachinery: filteredSiValues?.Machines || '',
      Property: filteredSiValues?.Structure || '',
      OfficeEquipment: filteredSiValues?.["IT Equipment"] || '',
      FurnitureFixture: filteredSiValues?.Furniture || '',
      Electronics: filteredSiValues?.["Electrical Equipments"] || '',
      Other: filteredSiValues?.Other || '',
    })
  }

  const handleSumInsuredDataSubmit = async (e, totalSI = sumInsured, filteredSiValues = sumInsuredValues) => {
    e.preventDefault()
    trackMixpanelEvent(mixpanel, `Clicked 'Proceed - Sum Insured collection'`, pageName)

    setSumInsured(totalSI)
    setSumInsuredValues(filteredSiValues)

    // preload next screen
    AddressCollection.preload()

    try {
      await saveLeadDataToContext(actions.setSumInsuredData)
      await new Promise(resolve => {
        dispatch({ 
          type: actions.setSumInsuredData,  
          data: { ownership, sumInsuredValues, otherItemsDesc, sumInsured: totalSI }
        })
        resolve()
      })
      // API call here only if autosave had failed before
      if (isAutoSaveError) {
        const data = {
          AssetValue: Number(totalSI),
          Ownership: ownership,
          SumInsuredBreakdown: getSiBreakdown(filteredSiValues),
          OtherItems: ownership === "RENTED" ? otherItemsDesc : "",
        }
        const prospectingData = getProspectingData(data, fieldWiseProspectingStates.sumInsuredBreakdown)
        setIsSubmitting(true)
        await sendProspectingData(prospectingData)
        setIsSubmitting(false)
        setIsAutoSaveError(false)
      }
      setScreen(googleForm.addressCollection)
      !renderAsPopup && scrollToTop()
    } catch (err) {
      handleApiError(err)
    }
  }

  const handleAddressDataSubmit = async (e) => {
    e.preventDefault()
    trackMixpanelEvent(mixpanel, `Clicked 'Proceed - Address collection'`, pageName)
    await saveLeadDataToContext(actions.setAddressData)

    // condition check to show self serve flow
    const otherToF = !typesOfFacilities.includes(typeOfFacility)
    const otherNoB = natureOfBusiness !== "" && !allNOBs.includes(natureOfBusiness)
    const wholeBuildingOrMultipleFloors = floor === "Whole Building" || floor === "Multiple Floors"

    const showSelfServeFlow = !(otherToF || otherNoB || wholeBuildingOrMultipleFloors)
    try {
      setIsSubmitting(true)
      // API call here only if autosave had failed earlier
      if (isAutoSaveError) {
        const data = {
          Address: address,
          Floor: floor,
          PIN: pincode
        }
        const prospectingData = getProspectingData(data, fieldWiseProspectingStates.floor)
        await sendProspectingData(prospectingData)
        setIsAutoSaveError(false)
      }

      if (showSelfServeFlow) {
        // setIsSubmitting(true)
        // check if the occupancy qualifies for instant quotes on the backend
        const response = await checkIfInstantQuoteOccupancy(typeOfFacility, natureOfBusiness)
        if (response?.isInstantOccupancy) {
          const prospectingData = getProspectingData({}, fieldWiseProspectingStates.selfServe)
          await sendProspectingData(prospectingData)
          await createContact(customerName, mobileNumWithCountryCode(mobile))
          if (formType === "website") {
            const updatedLeadFormData = { ...leadFormData, pincode, address, city, stateVal }
            navigate("/self-serve", {
              state: { leadFormData: updatedLeadFormData }
            })
          } else {
            setHeroSectionScreen("self-serve")
          }
          return
        }
      }
      // preload next screen
      EstimatedQuote.preload()
      
      trackMixpanelEvent(mixpanel, `Viewed Provisional quote`, pageName)

      // provisional quote API call
      await getEstimatedPremium()
    } catch (err) {
      handleApiError(err)
    }
  }

  const handleGoBack = () => {
    let prevScreen = null
    let heroSectionScreen = null

    if (screen === googleForm.addressCollection) {
      prevScreen = googleForm.sumInsuredCollection
      heroSectionScreen = screens.factsAboutUs
    
    } else if (screen === googleForm.sumInsuredCollection) {
      if (collectGoodsDataForToFs.includes(typeOfFacility) || hideNOBforBusinessTypes.includes(typeOfFacility)) {
        prevScreen = googleForm.goodsDataCollection
      } else if (typeOfFacility === "Godown/Warehouse" || typeOfFacility === "Factory/Manufacturer") {
        prevScreen = googleForm.materialDataCollection
      } else {
        prevScreen = googleForm.roofWallMaterialsCollection
      }
      heroSectionScreen = screens.totalStats
    
    } else if (screen === googleForm.goodsDataCollection || screen === googleForm.materialDataCollection) {
      prevScreen = googleForm.roofWallMaterialsCollection
      heroSectionScreen = screens.totalStats
    
    } else if (screen === googleForm.roofWallMaterialsCollection) {
      prevScreen = googleForm.businessTypeSelection
      heroSectionScreen = screens.claimTestimonial
    }

    prevScreen && setScreen(prevScreen)
    heroSectionScreen && setHeroSectionScreen(heroSectionScreen)
  }
  
  let stepContainer = null;

  if (screen === commonFormScreens.leadDataCollection) 
    stepContainer = (
      <LeadDataCollection
        companyName={companyName}
        setCompanyName={setCompanyName}
        customerName={customerName}
        setCustomerName={setCustomerName}
        mobile={mobile}
        setMobile={handleMobileNumber}
        waUpdates={waUpdates}
        setWaUpdates={setWaUpdates}
        onFormSubmit={handleLeadDataSubmit}
        fallback={<LoadingComp />}
        policyType={policyType}
        isPopup={renderAsPopup}
        formType={formType}
        newTheme={newTheme}
        quoteCtaProps={quoteCtaProps}
        isSafetyPlan={isSafetyPlan}
      />
    )
  else if (screen === commonFormScreens.provisionalQuote) 
    stepContainer = (
      <EstimatedQuote 
        estimatedQuote={estimatedQuote}
        sumInsured={sumInsured}
        phone={mobile}
        newTheme={newTheme}
        getProspectingData={getProspectingData}
        pincode={pincode}
        city={city}
        stateVal={stateVal}
        handleApiError={handleApiError}
        autoSaveData={autoSaveData}
        isAutoSaving={isAutoSaving}
        isAutoSaveError={isAutoSaveError}
        setIsAutoSaveError={setIsAutoSaveError}
        fallback={<LoadingComp />}
      />
    )
  else if (screen === websiteForm.leadDataCollectionSuccess)
    stepContainer = <ThankYouScreen isSafetyPlan={isSafetyPlan} sumInsured={sumInsured} newTheme={newTheme} fallback={<LoadingComp />} />
  else if (screen === websiteForm.policyTypeChoice) 
    stepContainer = <PolicyTypeSelection onOptionSelect={handlePolicyTypeSelection} newTheme={newTheme} selectedOption={policyType} />
  // new QnA journey screens
  else if (screen === googleForm.businessTypeSelection) {
    stepContainer = (
      <BusinessTypeSelection 
        typeOfFacility={typeOfFacility}
        setTypeOfFacility={setTypeOfFactlityValue}
        natureOfBusiness={natureOfBusiness}
        setNatureOfBusiness={setNatureOfBusiness}
        companyName={companyName}
        onFormSubmit={handleBusinessTypeDataSubmit}
        newTheme={newTheme}
        autoSaveData={autoSaveData}
        isAutoSaving={isAutoSaving}
        fallback={<LoadingComp />}
      />
    )
  } else if (screen === googleForm.roofWallMaterialsCollection) {
    stepContainer = (
      <RoofWallMaterialsCollection 
        typeOfFacility={typeOfFacility}
        roofMaterial={roofMaterial}
        setRoofMaterial={setRoofMaterial}
        wallMaterial={wallMaterial}
        setWallMaterial={setWallMaterial}
        onFormSubmit={handleRoofWallMaterialsDataSubmit}
        onGoBack={handleGoBack}
        newTheme={newTheme}
        autoSaveData={autoSaveData}
        isAutoSaving={isAutoSaving}
        fallback={<LoadingComp />}
      />
    )
  } else if (screen === googleForm.goodsDataCollection) {
    stepContainer = (
      <GoodsDataCollection 
        goods={goods}
        setGoods={setGoods}
        stockData={stockData}
        setStockData={setStockData}
        setTotalSiVal={setSumInsured}
        sumInsuredValues={sumInsuredValues}
        setSumInsuredValues={setSumInsuredValues}
        getSiBreakdown={getSiBreakdown}
        typeOfFacility={typeOfFacility}
        natureOfBusiness={natureOfBusiness}
        onFormSubmit={handleGoodsMaterialsDataSubmit}
        onGoBack={handleGoBack}
        newTheme={newTheme}
        autoSaveData={autoSaveData}
        isAutoSaving={isAutoSaving}
        fallback={<LoadingComp />}
      />
    )
  } else if (screen === googleForm.materialDataCollection) {
    stepContainer = (
      <MaterialsDataCollection 
        rawMaterials={rawMaterials}
        setRawMaterials={setRawMaterials}
        finishedGoods={finishedGoods}
        setFinishedGoods={setFinishedGoods}
        stockData={stockData}
        setStockData={setStockData}
        setTotalSiVal={setSumInsured}
        sumInsuredValues={sumInsuredValues}
        setSumInsuredValues={setSumInsuredValues}
        getSiBreakdown={getSiBreakdown}
        typeOfFacility={typeOfFacility}
        natureOfBusiness={natureOfBusiness}
        onFormSubmit={handleGoodsMaterialsDataSubmit}
        onGoBack={handleGoBack}
        newTheme={newTheme}
        autoSaveData={autoSaveData}
        isAutoSaving={isAutoSaving}
        fallback={<LoadingComp />}
      />
    )
  } else if (screen === googleForm.sumInsuredCollection) {
    stepContainer = (
      <SumInsuredCollection 
        facilityType={typeOfFacility}
        ownership={ownership}
        setOwnership={setOwnership}
        goods={goods}
        rawMaterials={rawMaterials}
        finishedGoods={finishedGoods}
        sumInsuredValues={sumInsuredValues}
        setSumInsuredValues={setSumInsuredValues}
        otherItemsDesc={otherItemsDesc}
        setOtherItemsDesc={setOtherItemsDesc}
        onFormSubmit={handleSumInsuredDataSubmit}
        onGoBack={handleGoBack}
        newTheme={newTheme}
        autoSaveData={autoSaveData}
        getSiBreakdown={getSiBreakdown}
        isAutoSaving={isAutoSaving}
        isPopup={renderAsPopup}
        fallback={<LoadingComp />}
      />
    )
  } else if (screen === googleForm.addressCollection) {
    stepContainer = (
      <AddressCollection 
        address={address}
        setAddress={setAddress}
        floor={floor}
        setFloor={setFloor}
        pincode={pincode}
        setPincode={handlePincode}
        city={city}
        setCity={setCity}
        state={stateVal}
        setStateVal={setStateVal}
        typeOfFacility={typeOfFacility}
        onFormSubmit={handleAddressDataSubmit}
        onGoBack={handleGoBack}
        newTheme={newTheme}
        autoSaveData={autoSaveData}
        isAutoSaving={isAutoSaving}
        isPopup={renderAsPopup}
        fallback={<LoadingComp />}
      />
    )
  }

  if (isSubmitting) {
    stepContainer = (<>
      <Text
        fontSize="24px"
        mfontSize="18px"
        color={"#4D4D4D"}
        fontWeight="bold"
        style={{ marginBottom: '50px' }}
      >
        Submitting...
      </Text>
      <Loader style={{marginBottom: "5rem"}} />
    </>)
  }

  const content = (
    <ModalContainer style={style} popup={renderAsPopup}>
      {!isSubmitting && renderAsPopup && <StyledCloseIcon onClick={handleClose} />}
      {stepContainer}
      {error && <Text color="red">{error}</Text>}
      {isAutoSaving && <Text color="blue">Saving...</Text>}
    </ModalContainer>
  )

  if (renderAsPopup) {
    return (
      <ModalBackground>
        {content}
      </ModalBackground>
    )
  }

  return content;
}

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const ModalBackground = styled.div`
  position: fixed;
  width: 100%;
  z-index: 1100;
  height: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const ModalContainer = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 16px;
  padding: 1.5rem;
  padding-top: ${p => p.popup ? "2rem" : "0"};
  margin-top: ${p => p.popup ? "12vh" : "0"};
  display: flex;
  flex-direction: column;
  width: min(480px, 100%);
  height: fit-content;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 95%;
    margin-top: ${p => p.popup ? "20vh" : "0"};
    margin-bottom: ${p => p.popup ? "4vh" : "0"};
    padding: ${p => p.popup ? "1.5rem 1rem" : "0.5rem 0.75rem" };
    padding-top: ${p => p.popup ? "2rem" : "1rem"};
    border-top: ${p => p.popup ? "none" : "1.5px solid #F8C6A7"};
    border: ${p => p.popup ? "16px" : "20px 20px 0 0"}
  }

  .input-icons {
    position: absolute;
    width: 20px;
    top: 34px;
    right: 14px;
  }
`;

export default React.forwardRef(LeadForm)